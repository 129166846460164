import React, { Fragment, useEffect, useState } from "react";
import Title from "../../../text/title";
import Toaster from "../../../common/Toaster";
import LinkText from "../../../text/linkText";
import RoundedBtn from "../../../button/roundedBtn";
import "./style.scss";
import OptionCard from "../../optionCard";
import Input from "../../../input";
import NavRight from "../../../button/navRight";
import NavLeft from "../../../button/navLeft";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { updateCurrentBrand as updateBrand } from "../../../../actions/brand/action";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";

const StepSix = ({ updateBrand, brandName, brandId }) => {
 
  const [isMobile, setMobile] = useState(false);
  const [name, updateBrandName] = useState("");
  const [localstorageUserState, setLocalstorageUserState] = useState({});

  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {

    const localstorageUser = JSON.parse(localStorage.getItem("registeringUser"));
    setLocalstorageUserState(localstorageUser);
    updateBrandName(
      localstorageUser && localstorageUser.brandName
        ? localstorageUser.brandName
        : ""
    )

    if (!localstorageUser) {
      if (!localstorageUser || !localstorageUser.name) {
        navigate("/onboarding/user-details");
      } else if (!localstorageUser || !localstorageUser.state) {
        navigate("/onboarding/brand-location");
      } else if (!localstorageUser || !localstorageUser.age) {
        navigateBack();
      }
    }
  }, []);

  // useEffect(() => {
  //   if (brandName) navigate("/onboarding/brand-website");
  // }, [brandName]);

  const onSubmit = async () => {
    // if (!name) {
    //   setToastrMsg("Please enter a name");
    //   doRefresh((prev) => prev + 1);
    //   return;
    // }
    localstorageUserState.brandName = name;
    localStorage.setItem("registeringUser", JSON.stringify(localstorageUserState));
    var updateBrandResonse = await updateBrand(brandId, { name });
    if (updateBrandResonse && !updateBrandResonse.error) {
      navigate("/onboarding/brand-website");
    } else {
      setToastrMsg("Unable to add Brand Name");
      doRefresh((prev) => prev + 1);
    }
  };

  const navigateBack = () => {
    navigate("/onboarding/brand-age");
  };

  return (
    <Fragment>
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <div className="onboarding row brand-name-container">
        <div className="top">
          <img src="/images/common/plus_new_logo.svg" />
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image">
          <div className="d-flex justify-content-center margin-top-img lottie">
            {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
            <div className="lottie-subtext">
              Ok{" "}
              <span>
                {localstorageUserState &&
                  localstorageUserState.name &&
                  localstorageUserState.name.split(' ')[0]}
                ,
              </span>{" "}
              a couple <br />
              {/* <span>Joey</span>,  */}
              more questions and <br />
              you’re all set!
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
          <div className="brand-focus-heading">
            <Title text="What’s your brand name?" />
          </div>

          <div className="row brand-name">
            <Input
              placeholder="Type here"
              value={name}
              onChange={(e) => updateBrandName(e.target.value)}
            />
            <div className="name-subtext">
              If you don’t have one yet, skip it.
            </div>
          </div>
        </div>
        <div className={!isMobile ? "row" : ""}>
          <div className="col-12 col-md-6 col-sm-6 col-xl-6 to-right hpx"></div>
          <div className="col-12 col-md-6 col-sm-6 col-xl-6 to-left">
            <div className="nav-conatiner">
              <div>
                <span className="text-decoration-none">
                  <NavLeft onClick={() => navigateBack()} />
                  <span className="nav-text" onClick={() => navigateBack()}>
                    Back
                  </span>
                </span>
              </div>
              <div>
                <span className="text-decoration-none">
                  <span className="nav-text" onClick={() => onSubmit()}>
                    Next
                  </span>
                  <NavRight onClick={() => onSubmit()} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ brand }) => ({
  brandName: brand.name,
  brandId: brand.brandId,
});

export default connect(mapStateToProps, { updateBrand })(StepSix);
