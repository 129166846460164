import React from "react";
import StepSix from "../../components/cards/onboardingCard/stepSix";
import "./style.scss";
import { LiveChatWidget } from '@livechat/widget-react'
import StepThree from "../../components/cards/onboardingCard/stepThree";

const BrandName = () => {
  return (
    <div className="bg bg-img">
      <div className="mid-card card-4">
      <StepThree />
        {/* <StepSix /> */}
      </div>
      {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
    </div>
  );
};

export default BrandName;
